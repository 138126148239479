<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      리뷰어 채널 등록 신청하기<router-link
        to=""
        @click.native="$router.push('/mypage/my_info')"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_35">
      <div class="box">
        <h4>
          리뷰어 채널은 회원권 제도로 운영됩니다.<router-link
            to=""
            @click.native="onOff"
            ><img src="@/assets/images/sub/my_up_ico.png" alt=""
          /></router-link>
        </h4>
        <div class="cont_box" v-if="infoBox">
          <p class="dot">
            리뷰어 채널은 회원권 제도로 운영됩니다. 램이 리뷰어 회원권 제도를
            운영하는 이유는 리뷰어들로 하여금 리뷰어로서 책임감과 자긍심을 갖게
            하기 위함입니다.
          </p>
          <p class="dot">
            램에서 판매하는 [리뷰어 회원권]은 [10일 회원권]이며, 10일 경과 후
            회원권은 무료로 유지됩니다. 회원권의 가격과 기간은 회사의 정책에
            따라 달라질 수 있으며 이는 기존 리뷰어 회원에게 소급 적용되지
            않습니다.
          </p>
          <p class="dot">
            리뷰어 회원권을 취득한 리뷰어는 램 플랫폼 안에서 또는 램 플랫폼을
            통해 원하는 상품과 서비스를 리뷰할 수 있는 권리를 얻게 됩니다.
          </p>
          <p class="dot">
            리뷰어 회원은 램 플랫폼에서 또는 램 플랫폼을 통해 리뷰한 리뷰를 통해
            램 플랫폼에 재정적 유익을 끼칠 경우, 보상 포인트라는 혜택을 받을 수
            있습니다. 보상 포인트의 양과 지급 방식은 회사의 정책에 따라 바뀔 수
            있으며, 이는 기존의 리뷰어 회원에게도 소급 적용됩니다. 자세한 사항은
            [램 이용약관]을 참조하세요.
          </p>
          <p class="dot">
            리뷰 보상 포인트는 언제든지 램 포인트로 전환할 수 있으며 램에서 상품
            또는 서비스를 구매/이용하는데 사용할 수 있습니다.
          </p>
          <p class="dot">
            리뷰어 회원권은 회원 탈퇴 시 자동 소멸되며 회원권 구매를 위해
            지불하신 돈은 환불되지 않습니다.
          </p>
        </div>
      </div>
      <div class="box">
        <div class="cont_box common">
          <ul>
            <li>
              <label
                ><input
                  type="checkbox"
                  name=""
                  @click="allCheck"
                />전체동의</label
              >
            </li>
            <li>
              <label
                ><input type="checkbox" name="" v-model="privacy" />개인정보
                수집이용 동의(필수)</label
              ><router-link to="" @click.native="open('privacy')"
                ><img src="@/assets/images/sub/read_agree.png" alt=""
              /></router-link>
            </li>
            <li>
              <label
                ><input type="checkbox" name="" v-model="provide" />만 14세
                이상입니다.(필수)</label
              >
            </li>
          </ul>
          <p>
            리뷰앤메이크머니(주)는 [개인정보 보호법] 제 조22 등 관련 법령을
            감안해 만 14세 미만 아동의 램 리뷰어 회원 가입을 불허합니다.
          </p>
        </div>
      </div>
      <div class="box">
        <h4>리뷰어 채널 정보 등록</h4>
        <div class="cont_box">
          <div class="input_box">
            <h5>리뷰어 닉네임</h5>
            <div class="inp_1">
              <input
                type="text"
                ref="nicname"
                name=""
                v-model="nickname"
                maxlength="10"
                @keyup="nickNameFalse"
              />
              <router-link to="" @click.native="dupleNickname"
                >중복확인</router-link
              >
            </div>
            <div class="inf">리뷰어로 활동할 닉네임을 입력해주세요.</div>
          </div>
          <div class="input_box">
            <h5>이메일 주소</h5>
            <div class="inp_2 none">
              <div>현재&nbsp;:&nbsp;</div>
              <input
                type="text"
                name=""
                :value="myInfo.email"
                readonly
              /><router-link
                to=""
                @click.native="authEmail"
                v-if="myInfo.auth_email !== 'Y'"
                >인증</router-link
              >
              <span v-else>인증완료</span>
            </div>
            <div class="inp_2">
              <div>변경&nbsp;:&nbsp;</div>
              <input type="text" name="" v-model="newEmail" /><router-link
                to=""
                @click.native="newAuthEmail"
                >인증</router-link
              >
            </div>
            <div class="inf">
              이메일 변경을 원하시면 새 이메일 주소를 입력하고 [인증]버튼을 누른
              후 램에서 발송된 메일을 열어 [이메일 인증하기]를 눌러주세요.
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <h4>리뷰어 회원권 구매내역</h4>
        <div class="cont_box">
          <table summary="">
            <colgroup>
              <col width="80px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <td>회원권 종류</td>
                <td>10일 회원권(10일 경과 후 무료)</td>
              </tr>
              <tr>
                <td>회원권 가격</td>
                <td>1,000원</td>
              </tr>
              <tr class="final">
                <td>결제금액</td>
                <td><span>1,000원</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="box">
        <h4>주의사항</h4>
        <div class="cont_box">
          <p>
            아래 [결제하기] 버튼을 눌러 결제를 완료하면 위에서 인증한 본인의
            이메일로 승인 여부가 통보됩니다. 리뷰어 채널등록이 승인된 램 회원은
            승인 통지 메일의 [램 마이페이지 가기] 버튼을 눌러 리뷰어 닉네임이나
            리뷰어 이미지 등 리뷰어 개인정보를 수정/등록하실 수 있습니다.
          </p>
        </div>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_btn yellow">
      <div>
        <router-link to="" @click.native="reviewerRegister"
          >결제하기</router-link
        >
      </div>
    </div>
    <Privacy class="modals" v-if="isPrivacy" :isModals="true" @open="open" />
    <form
      id="pg_form"
      method="post"
      action="/lg_ramm/reviewer_auth"
      target="tar_opener"
    >
      <input type="hidden" name="order_seq" value="" />
      <input type="hidden" name="mobilenew" value="" />
    </form>
    <iframe
      id="pay_frame"
      name="tar_opener"
      frameborder="0"
      width="100%"
      height="100%"
      scrolling="auto"
      class="pay_ments"
      style="display: none"
    ></iframe>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Privacy from "@/views/policy/Privacy.vue";
export default {
  data() {
    return {
      privacy: false,
      provide: false,
      nickname: null,
      sEncData: this.$route.query.EncodeData || null,
      type: this.$route.query.type || null,
      isPrivacy: false,
      infoBox: true,
      changeEmail: false,
      newEmail: null,
      // authData: {
      //   age: 15,
      // },
    };
  },
  computed: {
    ...mapState("mypage", [
      "myInfo",
      "isMailAuth",
      "msg",
      "result",
      "isNickname",
      "authNumber",
      "isPopup",
      "completeOrder",
    ]),
    ...mapState("member", {
      myMsg: "msg",
      myResult: "result",
      authData: "authData",
    }),
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      viewFooter: true,
    });
    this.$store.dispatch("mypage/clearAuth");
  },
  mounted() {
    if (this.type != null) {
      if (this.type === "fail") {
        this.$toast.default(
          "잠시 후 다시 시도하여주십시오. 오류가 계속 될 경우 고객센터로 문의하세요.[code:front]"
        );
        this.$router.push("/mypage/my_info");
        return false;
      }
    }
    this.userAuthPhoneCheck();
    window.addEventListener("message", this.orderReslut);
  },
  methods: {
    onOff(e) {
      this.infoBox = !this.infoBox;
      if (this.infoBox) {
        e.target.src = require("@/assets/images/sub/my_up_ico.png");
        return false;
      }
      e.target.src = require("@/assets/images/sub/my_down_ico.png");
    },
    allCheck() {
      this.privacy = !this.privacy;
      this.provide = !this.provide;
    },
    open(type) {
      document.querySelector("html").style.overflow = "hidden";
      switch (type) {
        case "privacy":
          this.isPrivacy = true;
          break;
        default:
          document.querySelector("html").style.overflow = "";
          this.isPrivacy = false;
          break;
      }
      console.log(document.querySelector("html").style.overflow);
    },
    ...mapMutations("mypage", ["updateData"]),
    nickNameFalse() {
      if (this.isNickname) {
        this.updateData({ isNickname: false });
        console.log("isNickname:", this.isNickname);
      }
    },
    closePop(e) {
      e.target.closest("div").style.display = "none";
      this.updateData({ isPopup: true });
    },
    async userAuthPhoneCheck() {
      await this.$store.dispatch("member/userAuthPhoneCheck", {
        EncodeData: this.sEncData,
      });
      if (!this.myResult) {
        this.$toast.default(this.myMsg);
        this.$router.push("/mypage/my_info");
        return false;
      }
    },
    async authEmail() {
      this.changeEmail = false;
      await this.$store.dispatch("mypage/authEmail", {
        email: this.myInfo.email,
        is_new: false,
      });
      this.$toast.default(this.msg);
    },
    newAuthEmail() {
      const reg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!reg.test(this.newEmail)) {
        this.$toast.default("올바른 이메일 주소가 아닙니다.");
        return false;
      }
      this.$confirm("이메일 주소를 변경하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("mypage/authEmail", {
            email: this.newEmail,
            is_new: true,
          });
          this.changeEmail = true;
          this.$toast.default(this.msg);
        })
        .catch(() => {
          return false;
        });
    },
    async dupleNickname() {
      const nickCheck = /[^a-z0-9가-힝\-_]/gi;
      if (!this.nickname) {
        this.$toast.default("닉네임을 입력해주세요.");
        return false;
      }
      if (this.nickname.replace(/\s/g, "").length < 3) {
        this.$toast.default("닉네임은 3글자 이상 입력해주세요.");
        return false;
      }
      if (nickCheck.test(this.nickname)) {
        this.$toast.default(
          "닉네임은 '영문,한글,숫자,-,_' 만 사용할 수 있습니다."
        );
        return false;
      }
      await this.$store.dispatch("mypage/dupleNickname", {
        nickname: this.nickname.trim(),
        channel: "reviewer",
      });
      this.$toast.default(this.msg);
    },
    async reviewerRegister() {
      if (!this.privacy) {
        this.$toast.default("개인정보 수집이용 동의에 동의 해주세요.");
        return false;
      }
      if (!this.provide) {
        this.$toast.default(
          "만 14세 미만 아동의 램 리뷰어 회원 가입을 불허합니다."
        );
        return false;
      }
      if (!this.isNickname) {
        this.$toast.default("닉네임 중복을 확인해주세요.");
        return false;
      }
      if (this.myInfo.auth_email !== "Y") {
        if (!this.isMailAuth) {
          this.$toast.default("이메일 인증을 해주세요.");
          return false;
        }
      }

      // const image1 = this.$refs.filedata1.files[0];
      // if (!image1) {
      //   this.$toast.default("주민등록증 인증 파일을 선택해 주세요.");
      //   return false;
      // }
      // let ext = image1.name.split(".").pop().toLowerCase();
      // let allow_ext = ["jpg", "png", "jpeg", "gif"];
      // let ext_check = allow_ext.some((item) => item === ext);
      // if (!ext_check) {
      //   this.$toast.default(
      //     "주민등록증 인증 파일이 허용되지 않는 파일 형식입니다."
      //   );
      //   this.$refs.filedata1.value = null;
      //   return false;
      // }

      // const image2 = this.$refs.filedata2.files[0];
      // if (!image2) {
      //   this.$toast.default("계좌 이미지 파일을 선택해 주세요.");
      //   return false;
      // }
      // ext = image2.name.split(".").pop().toLowerCase();
      // allow_ext = ["jpg", "png", "jpeg", "gif"];
      // ext_check = allow_ext.some((item) => item === ext);
      // if (!ext_check) {
      //   this.$toast.default("본인 계좌 파일이 허용되지 않는 파일 형식 입니다.");
      //   this.$refs.fiidata2.value = null;
      //   return false;
      // }

      let data = new FormData();
      // data.append("filedata1", image1);
      // data.append("filedata2", image2);
      if (!this.changeEmail) {
        data.append("email", this.myInfo.email);
      } else {
        data.append("email", this.newEmail);
      }
      data.append("nickname", this.nickname);
      data.append("auth_yn", this.authData.auth_yn);
      data.append("namecheck_birth", this.authData.namecheck_birth);
      data.append("namecheck_check", this.authData.namecheck_check);
      data.append("namecheck_name", this.authData.namecheck_name);
      data.append("namecheck_sex", this.authData.namecheck_sex);
      data.append("namecheck_type", this.authData.namecheck_type);
      data.append(
        "auth_number",
        !this.changeEmail && this.myInfo.auth_email == "Y"
          ? "Y"
          : this.authNumber
      );
      data.append("is_new_mail", this.changeEmail ? "Y" : "N");
      await this.$store.dispatch("mypage/reviewerRegister", data);
      if (this.result) {
        this.$store.dispatch("member/clearAuthData");
        // this.$toast.default(this.msg);
        // this.$router.push("/mypage/my_info");
        this.$store.dispatch("common/setLoading", true);
        const pgdata = this.completeOrder;
        const form = document.forms.pg_form;
        form.elements.order_seq.value = pgdata.order_seq;
        form.elements.mobilenew.value = "y";
        form.submit();
        document.querySelector("#pay_frame").style.display = "block";

        return false;
      }
      this.$toast.default(this.msg);
    },
    async orderReslut(event) {
      if (event.data.result) {
        console.log(event.data);
        document.querySelector("#pay_frame").style.display = "none";
        this.$toast.default(event.data.msg);
        // if (event.data.result === "done") {
        await this.$store.dispatch("mypage/getMypageMyinfo");
        this.$router.push("/mypage/my_info");
        // }
      } else {
        if (this.completeOrder && this.completeOrder.namecheck_check) {
          this.authData.namecheck_check = this.completeOrder.namecheck_check;
          this.authData.auth_yn = "Y";
        }
      }
      this.$store.dispatch("common/setLoading", false);
    },
  },
  components: {
    Privacy,
  },
  beforeDestroy() {
    document.querySelector("html").style.overflow = "";
    window.removeEventListener("message", this.orderReslut);
  },
};
</script>

<style lang="scss" scoped>
.sect_cert {
  span {
    margin-left: 10px;
    color: $info-txt;
  }
  input[type="text"] {
    height: 24px;
    background: #eee;
  }
}
.hr {
  padding: 5px 0;
}
.title {
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 15px;
}
.over_14 {
  margin: 10px 20px;
  div {
    padding: 10px 10px;
    background-color: #eee;
    border-radius: 3px;
    font-size: 12px;
    line-height: 15px;
  }
}
.rn_my_box_10 {
  .top {
    ul {
      width: auto !important;
      background-color: #eee;
      padding: 10px 10px;
      border-radius: 3px;
    }
  }
}
.modals {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background-color: #fff;
  overflow: scroll;
  height: calc(100% - 40px);
}
.pay_ments {
  margin: 0px auto 50px auto;
  position: fixed;
  z-index: 9999999999;
  top: 0;
}
</style>
